import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from 'src/app/Components/Global/Base/login/login.component';
import { HomeComponent } from 'src/app/Components/Global/Base/home/home.component';
import { DashboardComponent } from 'src/app/Components/evin/Dashboard/dashboard/dashboard.component';
import { OverviewComponent } from 'src/app/Components/evin/Dashboard/dashboard/overview/overview.component';
import { DashboardOverviewComponent } from 'src/app/Components/evin/Dashboard/dashboard/dashboard-overview/dashboard-overview.component';
/* import { StockReportViewComponent } from 'src/app/Components/evin/Inventory/stock-report/stock-report-view/stock-report-view.component';
import { StockReportMaterialComponent } from 'src/app/Components/evin/Inventory/stock-report/stock-report-material/stock-report-material.component';
import { StockReportMaterialStoreComponent } from 'src/app/Components/evin/Inventory/stock-report/stock-report-material-store/stock-report-material-store.component';
import { StockReportExpiresBeforeComponent } from 'src/app/Components/evin/Inventory/stock-report/stock-report-expires-before/stock-report-expires-before.component'; */
import { StockDeviantComponent } from 'src/app/Components/evin/Inventory/stock-deviant/stock-deviant.component';
import { TransactionsComponent } from 'src/app/Components/evin/Inventory/transactions/transactions.component';
import { StockAdjustmentsComponent } from 'src/app/Components/evin/Inventory/stock-adjustments/stock-adjustments.component';
import { OrdersComponent } from 'src/app/Components/evin/Orders/orders/orders.component';
import { ReleasesComponent } from 'src/app/Components/evin/Orders/releases/releases.component';
import { ShipmentsComponent } from 'src/app/Components/evin/Orders/shipments/shipments.component';
import { DiscrepanciesComponent } from 'src/app/Components/evin/Orders/discrepancies/discrepancies.component';
import { DemandComponent } from 'src/app/Components/evin/Orders/demand/demand.component';
import { ApprovalComponent } from 'src/app/Components/evin/Orders/approval/approval.component';
import { ShipmentsFulfillComponent } from 'src/app/Components/evin/Orders/shipments/shipments-fulfill/shipments-fulfill.component';
import { AssetsListComponent } from 'src/app/Components/evin/Assets/assets/assets-list/assets-list.component';
import { AssetsDetailsComponent } from 'src/app/Components/evin/Assets/assets/assets-details/assets-details.component';
import { UsersComponent } from 'src/app/Components/evin/Setup/users/users.component';
import { UsersDetailsComponent } from 'src/app/Components/evin/Setup/users/users-details/users-details.component';
import { UsersEditComponent } from 'src/app/Components/evin/Setup/users/users-edit/users-edit.component';
import { MaterialsComponent } from 'src/app/Components/evin/Setup/materials/materials.component';
import { MaterialsDetailsComponent } from 'src/app/Components/evin/Setup/materials/materials-details/materials-details.component';
import { MaterialsEditComponent } from 'src/app/Components/evin/Setup/materials/materials-edit/materials-edit.component';
import { HandlingUnitsComponent } from 'src/app/Components/evin/Setup/handling-units/handling-units.component';
import { HandlingUnitsDetailsComponent } from 'src/app/Components/evin/Setup/handling-units/handling-units-details/handling-units-details.component';
import { HandlingUnitsEditComponent } from 'src/app/Components/evin/Setup/handling-units/handling-units-edit/handling-units-edit.component';
import { SetupInventoryComponent } from 'src/app/Components/evin/Setup/setup-inventory/setup-inventory.component';
import { BulletinBoardComponent } from 'src/app/Components/evin/Setup/bulletin-board/bulletin-board.component';
import { TransportersComponent } from 'src/app/Components/evin/Setup/transporters/transporters.component';
import { TransportersDetailsComponent } from 'src/app/Components/evin/Setup/transporters/transporters-details/transporters-details.component';
import { TransportersEditComponent } from 'src/app/Components/evin/Setup/transporters/transporters-edit/transporters-edit.component';
import { ManufacturersComponent } from 'src/app/Components/evin/Setup/manufacturers/manufacturers.component';
import { ManufacturesDetailsComponent } from 'src/app/Components/evin/Setup/manufacturers/manufactures-details/manufactures-details.component';
import { ManufacturesEditComponent } from 'src/app/Components/evin/Setup/manufacturers/manufactures-edit/manufactures-edit.component';
import { FacilityGroupComponent } from 'src/app/Components/evin/Setup/facility-group/facility-group.component';
import { FacilityGroupEditComponent } from 'src/app/Components/evin/Setup/facility-group/facility-group-edit/facility-group-edit.component';
import { SetupAssetsComponent } from 'src/app/Components/evin/Setup/setup-assets/setup-assets.component';
import { FacilityComponent } from 'src/app/Components/evin/Setup/facility/facility.component';
import { FacilityDetailsComponent } from 'src/app/Components/evin/Setup/facility/facility-details/facility-details.component';
import { FacilitySummaryComponent } from 'src/app/Components/evin/Setup/facility/facility-summary/facility-summary.component';
import { SetupDomainsComponent } from 'src/app/Components/evin/Setup/setup-domains/setup-domains.component';
import { DomainActivityComponent } from 'src/app/Components/evin/Reports/Reports-activity/domain-activity/domain-activity.component';
import { UserActivityComponent } from 'src/app/Components/evin/Reports/Reports-activity/user-activity/user-activity.component';
import { TransactionCountsComponent } from 'src/app/Components/evin/Reports/Reports-activity/transaction-counts/transaction-counts.component';
import { AssetCapacityComponent } from 'src/app/Components/evin/Reports/Reports-assets/asset-capacity/asset-capacity.component';
import { AssetStatusComponent } from 'src/app/Components/evin/Reports/Reports-assets/asset-status/asset-status.component';
import { PowerAvailabilityComponent } from 'src/app/Components/evin/Reports/Reports-assets/power-availability/power-availability.component';
import { RspnsTmRparComponent } from 'src/app/Components/evin/Reports/Reports-assets/rspns-tm-rpar/rspns-tm-rpar.component';
import { SicknessRateComponent } from 'src/app/Components/evin/Reports/Reports-assets/sickness-rate/sickness-rate.component';
import { TempExcursionsComponent } from 'src/app/Components/evin/Reports/Reports-assets/temp-excursions/temp-excursions.component';
import { UpTimeComponent } from 'src/app/Components/evin/Reports/Reports-assets/up-time/up-time.component';
import { CstmRprtsComponent } from 'src/app/Components/evin/Reports/Custom-reports/cstm-rprts/cstm-rprts.component';
import { AbnormalStockComponent } from 'src/app/Components/evin/Reports/Reports-inventory/abnormal-stock/abnormal-stock.component';
import { ConsumptionComponent } from 'src/app/Components/evin/Reports/Reports-inventory/consumption/consumption.component';
import { DiscardsComponent } from 'src/app/Components/evin/Reports/Reports-inventory/discards/discards.component';
import { RplnshRspnTimeComponent } from 'src/app/Components/evin/Reports/Reports-inventory/rplnsh-rspn-time/rplnsh-rspn-time.component';
import { StockAvailabilityComponent } from 'src/app/Components/evin/Reports/Reports-inventory/stock-availability/stock-availability.component';
import { StockTrendsComponent } from 'src/app/Components/evin/Reports/Reports-inventory/stock-trends/stock-trends.component';
import { SupplyComponent } from 'src/app/Components/evin/Reports/Reports-inventory/supply/supply.component';
import { UtilizationComponent } from 'src/app/Components/evin/Reports/Reports-inventory/utilization/utilization.component';
import { OrderDemandComponent } from 'src/app/Components/evin/Reports/Reports-orders/order-demand/order-demand.component';
import { OrderDiscrepanciesComponent } from 'src/app/Components/evin/Reports/Reports-orders/order-discrepancies/order-discrepancies.component';
import { OrderResponseTimeComponent } from 'src/app/Components/evin/Reports/Reports-orders/order-response-time/order-response-time.component';
import { OrderStatusComponent } from 'src/app/Components/evin/Reports/Reports-orders/order-status/order-status.component';
import { ReportFilterComponent } from 'src/app/Components/evin/Reports/report-filter/report-filter.component';
import { CnfgGeneralComponent } from 'src/app/Components/evin/Configuration/cnfg-general/cnfg-general.component';
import { ConfgFormComponent } from 'src/app/Components/evin/Configuration/confg-form/confg-form.component';
import { DbInventoryComponent } from 'src/app/Components/evin/Dashboard/db-inventory/db-inventory.component';
import { DbAssetsComponent } from 'src/app/Components/evin/Dashboard/db-assets/db-assets.component';
import { DbCustomDashboardComponent } from 'src/app/Components/evin/Dashboard/db-custom-dashboard/db-custom-dashboard.component';
import { DbActivityComponent } from 'src/app/Components/evin/Dashboard/db-activity/db-activity.component';
import { DbOrdersComponent } from 'src/app/Components/evin/Dashboard/db-orders/db-orders.component';
import { CnfgCapabilitiesComponent } from 'src/app/Components/evin/Configuration/cnfg-capabilities/cnfg-capabilities.component';
import { CnfgInventoryComponent } from 'src/app/Components/evin/Configuration/cnfg-inventory/cnfg-inventory.component';
import { CnfgOrdersComponent } from 'src/app/Components/evin/Configuration/cnfg-orders/cnfg-orders.component';
import { CnfgApprovalsComponent } from 'src/app/Components/evin/Configuration/cnfg-approvals/cnfg-approvals.component';
import { CnfgNotificationsComponent } from 'src/app/Components/evin/Configuration/cnfg-notifications/cnfg-notifications.component';
import { CnfgTagsComponent } from 'src/app/Components/evin/Configuration/cnfg-tags/cnfg-tags.component';
import { CnfgAccountingComponent } from 'src/app/Components/evin/Configuration/cnfg-accounting/cnfg-accounting.component';
import { CnfgCustomReportsComponent } from 'src/app/Components/evin/Configuration/cnfg-custom-reports/cnfg-custom-reports.component';
import { CnfgAssetsComponent } from 'src/app/Components/evin/Configuration/cnfg-assets/cnfg-assets.component';
import { CnfgDashboardComponent } from 'src/app/Components/evin/Configuration/cnfg-dashboard/cnfg-dashboard.component';
import { CnfgEventSummaryComponent } from 'src/app/Components/evin/Configuration/cnfg-event-summary/cnfg-event-summary.component';
import { CnfgBulletinBoardComponent } from 'src/app/Components/evin/Configuration/cnfg-bulletin-board/cnfg-bulletin-board.component';
import { CnfgCustomDashboardComponent } from 'src/app/Components/evin/Configuration/cnfg-custom-dashboard/cnfg-custom-dashboard.component';
import { CnfgStockRebalancingComponent } from 'src/app/Components/evin/Configuration/cnfg-stock-rebalancing/cnfg-stock-rebalancing.component';
import { CnfgAddNotificationsComponent } from 'src/app/Components/evin/Configuration/cnfg-notifications/cnfg-add-notifications/cnfg-add-notifications.component';
import { DmnActvtyNewComponent } from 'src/app/Components/evin/Reports/Reports-activity/dmn-actvty-new/dmn-actvty-new.component';
import { ManageComponent } from 'src/app/Components/evin/Manage/manage.component';
import { DomainListComponent } from 'src/app/Components/evin/Manage/domain/domain-list/domain-list.component';
import { DomainCreateComponent } from 'src/app/Components/evin/Manage/domain/domain-create/domain-create.component';
import { SystemConfigComponent } from 'src/app/Components/evin/Manage/system-config/system-config.component';
import { ManageDataComponent } from 'src/app/Components/evin/Manage/manage-data/manage-data.component';
import { ChangePasswordComponent } from 'src/app/Components/Global/Authentication/change-password/change-password.component';
import { ResetPasswordComponent } from 'src/app/Components/Global/Authentication/reset-password/reset-password.component';
import { ForgotPasswordComponent } from 'src/app/Components/Global/Authentication/forgot-password/forgot-password.component';
import { CnfgOptionsComponent } from 'src/app/Components/evin/Configuration/cnfg-options/cnfg-options.component';
import { StockReportAllComponent } from 'src/app/Components/evin/Inventory/stock-report/stock-report-all/stock-report-all.component';
import { PucComponent } from 'src/app/Components/Global/Base/puc/puc.component';
import { ActivityRateComponent } from 'src/app/Components/evin/Reports/Reports-activity/activity-rate/activity-rate.component';
import { StockViewsComponent } from 'src/app/Components/evin/Inventory/stock-report/stock-views/stock-views.component';
import { StocksReportsComponent } from 'src/app/Components/evin/Reports/Reports-inventory/stocks-reports/stocks-reports.component';
import { ConsumptionNewComponent } from 'src/app/Components/evin/Reports/Reports-inventory/consumption/consumption-new/consumption-new.component';
import { TransfersComponent } from 'src/app/Components/evin/Orders/transfers/transfers.component';
import { UserExportsComponent } from 'src/app/Components/Global/User-account/user-exports/user-exports.component';
import { UserProfileComponent } from 'src/app/Components/Global/User-account/user-profile/user-profile.component';
import { OverviewDashboardComponent } from 'src/app/Components/evin/Dashboard/dashboard/overview-dashboard/overview-dashboard.component';
import { EStockReportComponent } from 'src/app/Components/evin/Inventory/stock-report/e-stock-report/e-stock-report.component';
import { ReportsHelpComponent } from 'src/app/Components/Global/Base/reports-help/reports-help.component';
import { OvrwDashboardNewComponent } from 'src/app/Components/evin/Dashboard/dashboard/ovrw-dashboard-new/ovrw-dashboard-new.component';
import { TxnExportsComponent } from 'src/app/Components/Global/User-account/txn-exports/txn-exports.component';
import { UsersViewComponent } from 'src/app/Components/evin/Setup/users/users-view/users-view.component';
import { FacilityViewComponent } from 'src/app/Components/evin/Setup/facility/facility-view/facility-view.component';
import { AssetsViewComponent } from 'src/app/Components/evin/Setup/setup-assets/assets-view/assets-view.component';
import { CnfgGnrlComponent } from 'src/app/Components/evin/Configuration/cnfg-gnrl/cnfg-gnrl.component';
import { OsmLeafletComponent } from 'src/app/Components/Global/osm-leaflet/osm-leaflet.component';
import { CcemanufacturersComponent } from './Components/evin/Setup/ccemanufacturers/ccemanufacturers.component';
import { PrchsIndentComponent } from './Components/evin/Orders/prchs-indent/prchs-indent.component';
import { CnsinWiseCnsumtonComponent } from './Components/evin/Reports/Reports-inventory/cnsin-wise-cnsumton/cnsin-wise-cnsumton.component';
import { PrchsIndentSummaryComponent } from './Components/evin/Orders/prchs-indent/prchs-indent-summary/prchs-indent-summary.component';
import { PrchsIndentStatusComponent } from './Components/evin/Orders/prchs-indent/prchs-indent-status/prchs-indent-status.component';
import { PrchsIndntDtlsComponent } from './Components/evin/Orders/prchs-indent/prchs-indnt-dtls/prchs-indnt-dtls.component';
import { PrchsIndntFulfullComponent } from './Components/evin/Orders/prchs-indent/prchs-indnt-fulfull/prchs-indnt-fulfull.component';
import { PrchsIndntCnsgneeComponent } from './Components/evin/Orders/prchs-indent/prchs-indnt-cnsgnee/prchs-indnt-cnsgnee.component';
import { CoverageReportComponent } from './Components/evin/Reports/Reports-inventory/coverage-report/coverage-report.component';
import { WastageReportComponent } from './Components/evin/Reports/Reports-inventory/wastage-report/wastage-report.component';
import { MnftrTrnsctnsComponent } from './Components/evin/Orders/orders/mnftr-trnsctns/mnftr-trnsctns.component';
import { ClosingStocksComponent } from './Components/evin/Reports/Reports-inventory/closing-stocks/closing-stocks.component';

const routes: Routes = [{
  path: 'home', component: HomeComponent, data: { breadcrumb: 'Home' }, children: [
    { path: 'puc', data: { breadcrumb: 'Development in-progress' }, component: PucComponent },
    { path: 'db-ovrvw', data: { breadcrumb: 'Dashboard  /  Overview' }, component: DashboardComponent },
    { path: 'ovrvw', data: { breadcrumb: 'Dashboard  /  Overview' }, component: OverviewComponent },
    { path: 'ovrvw-db', data: { breadcrumb: 'Dashboard  /  Overview' }, component: DashboardOverviewComponent },
    { path: 'db-ovrw', data: { breadcrumb: 'Dashboard  /  Overview' }, component: OverviewDashboardComponent },
    { path: 'db-ovrw-old', data: { breadcrumb: 'Dashboard  /  Overview' }, component: OvrwDashboardNewComponent },
    { path: 'stk-rprt', data: { breadcrumb: 'Stock Management  /  Stock Report' }, component: StockReportAllComponent},
    { path: 'e-stk-rprt', data: { breadcrumb: 'Stock Management  /  Stock Report' }, component: EStockReportComponent },
    { path: 'stk-view', data: { breadcrumb: 'Stock Management  /  Stock Report' }, component: StockViewsComponent },
    /* { path: 'stk-rprt', data: { breadcrumb: 'Stock Management  /  Stock Report' }, component: StockReportViewComponent },
    { path: 'stk-rprt-mtrl', data: { breadcrumb: 'Stock Management  /  Stock Report  /  Materials by Store' }, component: StockReportMaterialComponent },
    { path: 'stk-rprt-stre', data: { breadcrumb: 'Stock Management  /  Stock Report  /  Stores by Material' }, component: StockReportMaterialStoreComponent },
    { path: 'stk-rprt-exbf', data: { breadcrumb: 'Stock Management  /  Stock Report  /  Data by Expires before' }, component: StockReportExpiresBeforeComponent }, */
    /* { path: 'stk-rprt', data: { breadcrumb: 'Stock Management' }, component: StockReportComponent, children: [
      { path: 'view', data: { breadcrumb: 'Stock Report' }, component: StockReportViewComponent },
      { path: 'mtrl', data: { breadcrumb: 'Stock Report  /  Material' }, component: StockReportMaterialComponent },
    ]}, */
    { path: 'stk-dvnt', data: { breadcrumb: 'Stock Management  /  Stock Deviant' }, component: StockDeviantComponent },
    { path: 'trans', data: { breadcrumb: 'Stock Management  /  Transactions' }, component: TransactionsComponent },
    { path: 'stk-adjstm', data: { breadcrumb: 'Stock Management  /  Stock Adjustments' }, component: StockAdjustmentsComponent },
    { path: 'full-ship', data: { breadcrumb: 'Indents  /  fulfill shipment' }, component: ShipmentsFulfillComponent },
    { path: 'ord-ord', data: { breadcrumb: 'Indents  /  Indents' }, component: OrdersComponent },
    { path: 'prchs-ord', data: { breadcrumb: 'Indents  /  Purchase Indents' }, component: PrchsIndentComponent },
    { path: 'prchs-ord-smry', data: { breadcrumb: 'Indents  /  Purchase Indents' }, component: PrchsIndentSummaryComponent },
    { path: 'prchs-ord-cnsgnee', data: { breadcrumb: 'Indents  /  Purchase Indents' }, component: PrchsIndntCnsgneeComponent },
    { path: 'prchs-ord-sts', data: { breadcrumb: 'Indents  /  Purchase Indents' }, component: PrchsIndentStatusComponent },
    { path: 'prchs-ord-dtls', data: { breadcrumb: 'Indents  /  Purchase Indents' }, component: PrchsIndntDtlsComponent },
    { path: 'prchs-ord-fulfill', data: { breadcrumb: 'Indents  /  Purchase Indents' }, component: PrchsIndntFulfullComponent },
    { path: 'mnftr-trnsctns', data: { breadcrumb: 'Indents  /  Manufacturer Transactions' }, component: MnftrTrnsctnsComponent },
    { path: 'ord-rls', data: { breadcrumb: 'Indents  /  Releases' }, component: ReleasesComponent },
    { path: 'ord-transfers', data: { breadcrumb: 'Indents  /  Transfers' }, component: TransfersComponent },
    { path: 'ord-ship', data: { breadcrumb: 'Indents  /  Shipments' }, component: ShipmentsComponent },
    { path: 'ord-discrep', data: { breadcrumb: 'Indents  /  Discrepancies' }, component: DiscrepanciesComponent },
    { path: 'ord-demnd', data: { breadcrumb: 'Indents  /  Demand' }, component: DemandComponent },
    { path: 'ord-aprvl', data: { breadcrumb: 'Indents  /  Approvals' }, component: ApprovalComponent },
    { path: 'asst-asts', data: { breadcrumb: 'CCE Management' }, component: AssetsListComponent },
    { path: 'asst-ast-dtls', data: { breadcrumb: 'CCE Management  / CCE Management Details' }, component: AssetsDetailsComponent },
    { path: 'setup-users', data: { breadcrumb: 'Setup  /  Users' }, component: UsersComponent },
    { path: 'setup-users-list', data: { breadcrumb: 'Setup  /  Users' }, component: UsersViewComponent },
    { path: 'setup-users-details', data: { breadcrumb: 'Setup  /  User details' }, component: UsersDetailsComponent },
    { path: 'setup-users-edit', data: { breadcrumb: 'Setup  /  User Edit' }, component: UsersEditComponent },
    { path: 'setup-matrls', data: { breadcrumb: 'Setup  /  Materials' }, component: MaterialsComponent },
    { path: 'setup-matrls-details', data: { breadcrumb: 'Setup  /  Material details' }, component: MaterialsDetailsComponent },
    { path: 'setup-matrls-edit', data: { breadcrumb: 'Setup  /  Material Edit' }, component: MaterialsEditComponent },
    { path: 'setup-hand-units', data: { breadcrumb: 'Setup  /  Handling-Units' }, component: HandlingUnitsComponent },
    { path: 'setup-hand-units-details', data: { breadcrumb: 'Setup  /  Handling-Units Details' }, component: HandlingUnitsDetailsComponent },
    { path: 'setup-hand-units-edit', data: { breadcrumb: 'Setup  /  Handling-Units Edit' }, component: HandlingUnitsEditComponent },
    { path: 'setup-inv', data: { breadcrumb: 'Setup  /  Stock Management' }, component: SetupInventoryComponent },
    { path: 'setup-bultn-brd', data: { breadcrumb: 'Setup  /  Bulletin board' }, component: BulletinBoardComponent },
    { path: 'setup-transporters', data: { breadcrumb: 'Setup  /  Transporters' }, component: TransportersComponent },
    { path: 'setup-transporters-details', data: { breadcrumb: 'Setup  /  Transporters Details' }, component: TransportersDetailsComponent },
    { path: 'setup-transporters-edit', data: { breadcrumb: 'Setup  /  Transporters Edit' }, component: TransportersEditComponent },
    { path: 'setup-mfrs', data: { breadcrumb: 'Setup  /  Manufacturers' }, component: ManufacturersComponent },
    { path: 'setup-mfrs-details', data: { breadcrumb: 'Setup  /  Manufacturers Details' }, component: ManufacturesDetailsComponent },
    { path: 'setup-mfrs-edit', data: { breadcrumb: 'Setup  /  Manufacturers Edit' }, component: ManufacturesEditComponent },
    { path: 'setup-fclty-group', data: { breadcrumb: 'Setup  /  Facility Group' }, component: FacilityGroupComponent },
    { path: 'setup-fclty-edit', data: { breadcrumb: 'Setup  /  Facility Group Edit' }, component: FacilityGroupEditComponent },
    { path: 'setup-assets', data: { breadcrumb: 'Setup  /  CCE Management' }, component: SetupAssetsComponent },
    { path: 'setup-assets-list', data: { breadcrumb: 'Setup  /  CCE Management' }, component: AssetsViewComponent },
    { path: 'setup-fclty', data: { breadcrumb: 'Setup  /  Facility' }, component: FacilityComponent },
    { path: 'setup-fclty-list', data: { breadcrumb: 'Setup  /  Facility' }, component: FacilityViewComponent },
    { path: 'setup-fclty-details', data: { breadcrumb: 'Setup  /  Facility' }, component: FacilityDetailsComponent },
    { path: 'setup-fclty-smry', data: { breadcrumb: 'Setup  /  Facility' }, component: FacilitySummaryComponent },
    { path: 'setup-domns', data: { breadcrumb: 'Setup  /  Domains' }, component: SetupDomainsComponent },
    { path: 'cnfg-gnrl', data: { breadcrumb: 'Configuration  /  General' }, component: CnfgGnrlComponent },
    { path: 'confg-general', data: { breadcrumb: 'Configuration  /  General' }, component: CnfgGeneralComponent },
    { path: 'confg-cpblty', data: { breadcrumb: 'Configuration  /  capabilities' }, component: CnfgCapabilitiesComponent },
    { path: 'confg-inv', data: { breadcrumb: 'Configuration  /  Stock Management' }, component: CnfgInventoryComponent },
    { path: 'confg-ord', data: { breadcrumb: 'Configuration  /  Indents' }, component: CnfgOrdersComponent },
    { path: 'confg-aprove', data: { breadcrumb: 'Configuration  /  Approvals' }, component: CnfgApprovalsComponent },
    { path: 'confg-nfgs', data: { breadcrumb: 'Configuration  /  Notifications' }, component: CnfgNotificationsComponent },
    { path: 'confg-tags', data: { breadcrumb: 'Configuration  /  Tags' }, component: CnfgTagsComponent },
    { path: 'confg-acount', data: { breadcrumb: 'Configuration  /  Accounting' }, component: CnfgAccountingComponent },
    { path: 'confg-cstm-repts', data: { breadcrumb: 'Configuration  /  Custom Reports' }, component: CnfgCustomReportsComponent },
    { path: 'confg-assets', data: { breadcrumb: 'Configuration  / CCE Management' }, component: CnfgAssetsComponent },
    { path: 'confg-db', data: { breadcrumb: 'Configuration  /  Dashboard' }, component: CnfgDashboardComponent },
    { path: 'confg-options', data: { breadcrumb: 'Configuration  /  Options' }, component: CnfgOptionsComponent },
    { path: 'confg-evnt-sum', data: { breadcrumb: 'Configuration  /  Event Summary' }, component: CnfgEventSummaryComponent },
    { path: 'confg-bultn-board', data: { breadcrumb: 'Configuration  /  Bulletin-board' }, component: CnfgBulletinBoardComponent },
    { path: 'confg-custm-db', data: { breadcrumb: 'Configuration  /  Custom Dashboards' }, component: CnfgCustomDashboardComponent },
    { path: 'confg-stk-reblnc', data: { breadcrumb: 'Configuration  /  Stock Rebalancing' }, component: CnfgStockRebalancingComponent },
    { path: 'confg-add-notification', data: { breadcrumb: 'Configuration  /  Add Notifications' }, component: CnfgAddNotificationsComponent },
    { path: 'confg-form', data: { breadcrumb: 'Configuration  /  Form' }, component: ConfgFormComponent },
    { path: 'domain-activity', data: { breadcrumb: 'Reports  /  Activity / Domain activity' }, component: DomainActivityComponent },
    { path: 'dmn-actvty', data: { breadcrumb: 'Reports  /  Activity / Domain activity' }, component: DmnActvtyNewComponent },
    { path: 'trans-counts', data: { breadcrumb: 'Reports  /  Activity / Transaction counts' }, component: TransactionCountsComponent },
    { path: 'user-activity', data: { breadcrumb: 'Reports  /  Activity / User activity' }, component: UserActivityComponent },
    { path: 'act-rate', data: { breadcrumb: 'Reports  /  Activity / Activity rate' }, component: ActivityRateComponent },
    { path: 'rep-asset-capacity', data: { breadcrumb: 'Reports  /  CCE Management / CCE Management capacity' }, component: AssetCapacityComponent },
    { path: 'rep-asset-status', data: { breadcrumb: 'Reports  /  CCE Management / CCE Management status' }, component: AssetStatusComponent },
    { path: 'power-availability', data: { breadcrumb: 'Reports  /  CCE Management / Power availability' }, component: PowerAvailabilityComponent },
    { path: 'rspns-tm-rpar', data: { breadcrumb: 'Reports  /  CCE Management / Respone time to repair ' }, component: RspnsTmRparComponent },
    { path: 'sickness-rate', data: { breadcrumb: 'Reports  /  CCE Management / Sickness rate' }, component: SicknessRateComponent },
    { path: 'temp-excursion', data: { breadcrumb: 'Reports  /  CCE Management / Temperature excursions' }, component: TempExcursionsComponent },
    { path: 'up-time', data: { breadcrumb: 'Reports  /  CCE Management / Up time' }, component: UpTimeComponent },
    { path: 'rep-custom-reports', data: { breadcrumb: 'Reports  /  Custom reports' }, component: CstmRprtsComponent },
    { path: 'stks-rprts', data: { breadcrumb: 'Reports  /   Stock Management / Stock report' }, component: StocksReportsComponent },
    { path: 'cnsin-wise-cnsumton', data: { breadcrumb: 'Reports  /   Stock Management / Consignee wise consumption' }, component: CnsinWiseCnsumtonComponent },
    { path: 'abnormal-stock', data: { breadcrumb: 'Reports  /   Stock Management / Abnormal stock' }, component: AbnormalStockComponent },
    { path: 'consumption', data: { breadcrumb: 'Reports  /   Stock Management / Consumption' }, component: ConsumptionNewComponent },
    { path: 'coverage', data: { breadcrumb: 'Reports  /   Stock Management / Coverage Report' }, component: CoverageReportComponent },
    { path: 'wastage', data: { breadcrumb: 'Reports  /   Stock Management / Wastage Report' }, component: WastageReportComponent },
    { path: 'discards', data: { breadcrumb: 'Reports  /   Stock Management / Discards' }, component: DiscardsComponent },
    { path: 'rplnsh-rspn-time', data: { breadcrumb: 'Reports  /   Stock Management / Replenishment response time' }, component: RplnshRspnTimeComponent },
    { path: 'stock-availability', data: { breadcrumb: 'Reports  /   Stock Management / Stock availability' }, component: StockAvailabilityComponent },
    { path: 'stock-trends', data: { breadcrumb: 'Reports  /   Stock Management / Stock trends' }, component: StockTrendsComponent },
    { path: 'closing-stock', data: { breadcrumb: 'Reports  /   Stock Management / Closing Stock' }, component: ClosingStocksComponent },
    { path: 'supply', data: { breadcrumb: 'Reports  /   Stock Management / Supply' }, component: SupplyComponent },
    { path: 'utilization', data: { breadcrumb: 'Reports  /   Stock Management / Utilization' }, component: UtilizationComponent },
    { path: 'demand', data: { breadcrumb: 'Reports  /   Indents / Demand' }, component: OrderDemandComponent },
    { path: 'discrepancies', data: { breadcrumb: 'Reports  /   Indents / Indent discrepancies' }, component: OrderDiscrepanciesComponent },
    { path: 'ord-res-time', data: { breadcrumb: 'Reports  /   Indents / Indent response time' }, component: OrderResponseTimeComponent },
    { path: 'order-status', data: { breadcrumb: 'Reports  /   Indents / Indent status' }, component: OrderStatusComponent },
    { path: 'report-filter', data: { breadcrumb: 'Reports  /   Report Filter' }, component: ReportFilterComponent },
    { path: 'db-inv', data: { breadcrumb: 'Dashboard  /  Stock Management ' }, component: DbInventoryComponent },
    { path: 'db-assets', data: { breadcrumb: 'Dashboard  /  CCE Management ' }, component: DbAssetsComponent },
    { path: 'db-custom-db', data: { breadcrumb: 'Dashboard  /  Customdashboard ' }, component: DbCustomDashboardComponent },
    { path: 'db-activity', data: { breadcrumb: 'Dashboard  /  Activity ' }, component: DbActivityComponent },
    { path: 'db-orders', data: { breadcrumb: 'Dashboard  /  Indents ' }, component: DbOrdersComponent },    
    { path: 'mng', data: { breadcrumb: 'Manage' }, component: ManageComponent },
    { path: 'mng-data', data: { breadcrumb: 'Manage  /  Data' }, component: ManageDataComponent },
    { path: 'mng-sys-cnfg', data: { breadcrumb: 'Manage  /  System Configuration' }, component: SystemConfigComponent },
    { path: 'mng-dmns-lst', data: { breadcrumb: 'Manage  /  Domains' }, component: DomainListComponent },
    { path: 'mng-dmns-new', data: { breadcrumb: 'Manage  /  Domains  /  Create' }, component: DomainCreateComponent },
    { path: 'mng-dmn-dtls', data: { breadcrumb: 'Manage  /  Domains  /  Summary' }, component: ManageComponent },
    { path: 'chng-pswd', data: { breadcrumb: 'Change Password' }, component: ChangePasswordComponent },
    { path: 'user-exports', data: { breadcrumb: 'My Exports' }, component: UserExportsComponent },
    { path: 'user-profile', data: { breadcrumb: 'My Profile' }, component: UserProfileComponent },
    { path: 'txn-exports', data: { breadcrumb: 'Transactions Exports' }, component: TxnExportsComponent },
    { path: 'setup-ccemfrs', data: { breadcrumb: 'Setup  / CCE Manufacturers' }, component: CcemanufacturersComponent },

  ]},
  { path: 'frgt-pswd', data: { breadcrumb: 'Change Password' }, component: ForgotPasswordComponent },
  { path: 'lflt', component: OsmLeafletComponent },
  { path: 'trans-counts/help', component: ReportsHelpComponent },
  { path: 'dmn-actvty/help', component: ReportsHelpComponent },
  { path: 'act-rate/help', component: ReportsHelpComponent },
  { path: 'ord-res-time/help', component: ReportsHelpComponent },
  { path: 'closing-stock/help', component: ReportsHelpComponent },
  { path: 'rep-asset-capacity/help', component: ReportsHelpComponent },
  { path: 'discrepancies/help', component: ReportsHelpComponent },
  { path: 'stock-trends/help', component: ReportsHelpComponent },
  { path: 'stock-availability/help', component: ReportsHelpComponent },
  { path: 'consumption/help', component: ReportsHelpComponent },
  { path: 'stks-rprts/help', component: ReportsHelpComponent },
  { path: 'discards/help', component: ReportsHelpComponent },
  { path: 'wastage/help', component: ReportsHelpComponent },
  { path: 'abnormal-stock/help', component: ReportsHelpComponent },
  { path: 'up-time/help', component: ReportsHelpComponent },
  { path: 'user-activity/help', component: ReportsHelpComponent },
  { path: 'rep-asset-status/help', component: ReportsHelpComponent },
  { path: 'power-availability/help', component: ReportsHelpComponent },
  { path: 'rspns-tm-rpar/help', component: ReportsHelpComponent },
  { path: 'temp-excursion/help', component: ReportsHelpComponent },
  { path: 'rest-pswd', data: { breadcrumb: 'Change Password' }, component: ResetPasswordComponent },
  { path: 'invalid', component: LoginComponent },
  { path: '', component: LoginComponent },
  { path: '**', redirectTo: 'invalid' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true,  anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }